import ServiceLayout from './ServiceLayout';

const AIAutomation = () => {
  const features = [
    {
      title: 'Workflow Automation',
      description: 'Streamline repetitive tasks and processes with AI-powered automation solutions.',
    },
    {
      title: 'AI Integration',
      description: 'Integrate AI capabilities into your existing systems and applications.',
    },
    {
      title: 'Custom AI Solutions',
      description: 'Develop tailored AI solutions to address your specific business challenges.',
    },
    {
      title: 'Natural Language Processing',
      description: 'Implement NLP solutions for text analysis, chatbots, and content generation.',
    },
    {
      title: 'Machine Learning Models',
      description: 'Create and deploy custom machine learning models for your business needs.',
    },
    {
      title: 'Data Analysis',
      description: 'Extract valuable insights from your data using AI and machine learning.',
    },
  ];

  const technologies = [
    'OpenAI GPT',
    'TensorFlow',
    'PyTorch',
    'Python',
    'Node.js',
    'Docker',
    'AWS',
    'MongoDB',
  ];

  return (
    <ServiceLayout
      title="AI Workflow Automation"
      description="Transform your business operations with cutting-edge AI solutions and automated workflows."
      features={features}
      technologies={technologies}
      image="https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80&w=1500"
    />
  );
};

export default AIAutomation;