import ServiceLayout from './ServiceLayout';

const TechnicalConsulting = () => {
  const features = [
    {
      title: 'Technical Strategy',
      description: 'Develop comprehensive technical strategies aligned with your business goals.',
    },
    {
      title: 'Architecture Design',
      description: 'Design scalable and maintainable system architectures.',
    },
    {
      title: 'Technology Selection',
      description: 'Choose the right technologies and tools for your projects.',
    },
    {
      title: 'Security Assessment',
      description: 'Evaluate and improve your application security measures.',
    },
    {
      title: 'Code Review',
      description: 'Review and optimize your codebase for better performance and maintainability.',
    },
    {
      title: 'Team Training',
      description: 'Provide technical training and mentorship for your development team.',
    },
  ];

  const technologies = [
    'System Architecture',
    'Cloud Services',
    'DevOps',
    'Security',
    'Agile Methodologies',
    'Code Quality',
    'Performance Optimization',
    'Technical Documentation',
  ];

  return (
    <ServiceLayout
      title="Technical Consulting"
      description="Get expert guidance and solutions for your technical challenges and projects."
      features={features}
      technologies={technologies}
      image="https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&q=80&w=1500"
    />
  );
};

export default TechnicalConsulting;