import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ExternalLink, Github, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const projects = {
  'ecommerce-platform': {
    title: 'E-commerce Platform',
    description: 'A full-featured e-commerce solution with AI-powered recommendations',
    fullDescription: `
      Built a comprehensive e-commerce platform that leverages AI to provide personalized product recommendations. 
      The system includes inventory management, order processing, and analytics dashboard.
    `,
    image: 'https://images.unsplash.com/photo-1661956602116-aa6865609028?auto=format&fit=crop&q=80&w=1500',
    technologies: ['React', 'Node.js', 'PostgreSQL', 'TensorFlow', 'Docker', 'AWS'],
    features: [
      'AI-powered product recommendations',
      'Real-time inventory management',
      'Secure payment processing',
      'Analytics dashboard',
      'Mobile-responsive design',
    ],
    demo: 'https://example.com',
    github: 'https://github.com',
    prototype: 'https://figma.com',
  },
  'ai-workflow': {
    title: 'AI Workflow Automation',
    description: 'Automated content generation and social media management system',
    fullDescription: `
      Developed an AI-powered workflow automation system that generates content and manages social media posts.
      The system uses natural language processing to create engaging content and optimize posting schedules.
    `,
    image: 'https://images.unsplash.com/photo-1518770660439-4636190af475?auto=format&fit=crop&q=80&w=1500',
    technologies: ['Python', 'OpenAI', 'React', 'MongoDB', 'Docker'],
    features: [
      'Automated content generation',
      'Social media scheduling',
      'Analytics and reporting',
      'Custom workflow creation',
      'Integration with major platforms',
    ],
    demo: 'https://example.com',
    github: 'https://github.com',
    prototype: 'https://figma.com',
  },
  'business-directory': {
    title: 'Local Business Directory',
    description: 'SEO-optimized directory platform for local businesses',
    fullDescription: `
      Created a comprehensive business directory platform optimized for local SEO.
      The platform includes advanced search capabilities, business profiles, and review system.
    `,
    image: 'https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&q=80&w=1500',
    technologies: ['React', 'GraphQL', 'PostgreSQL', 'Elasticsearch', 'AWS'],
    features: [
      'Advanced search functionality',
      'Business profiles',
      'Review and rating system',
      'SEO optimization',
      'Analytics dashboard',
    ],
    demo: 'https://example.com',
    github: 'https://github.com',
    prototype: 'https://figma.com',
  },
};

const ProjectDetails = () => {
  const { id } = useParams();
  const project = projects[id as keyof typeof projects];

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="space-y-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="relative rounded-3xl overflow-hidden"
      >
        <div className="absolute inset-0">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-full object-cover opacity-20"
          />
        </div>
        <div className="relative bg-gradient-to-r from-green-500/10 via-yellow-500/10 to-purple-500/10 p-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">{project.title}</h1>
          <p className="text-xl text-gray-600 max-w-2xl">{project.fullDescription}</p>
        </div>
      </motion.div>

      <div className="grid md:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="space-y-6"
        >
          <div className="bg-white p-6 rounded-xl shadow-md">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Technologies Used</h2>
            <div className="flex flex-wrap gap-2">
              {project.technologies.map((tech) => (
                <span
                  key={tech}
                  className="px-4 py-2 bg-gradient-to-r from-green-100 to-purple-100 text-gray-800 rounded-full text-sm"
                >
                  {tech}
                </span>
              ))}
            </div>
          </div>

          <div className="bg-white p-6 rounded-xl shadow-md">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Key Features</h2>
            <ul className="space-y-2">
              {project.features.map((feature) => (
                <li key={feature} className="flex items-center text-gray-700">
                  <ArrowRight className="h-4 w-4 text-green-600 mr-2" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="space-y-6"
        >
          <div className="bg-white p-6 rounded-xl shadow-md">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Project Links</h2>
            <div className="space-y-4">
              <a
                href={project.demo}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between p-4 bg-gradient-to-r from-green-100 to-purple-100 rounded-lg hover:from-green-200 hover:to-purple-200 transition-colors"
              >
                <span className="font-semibold text-gray-800">Live Demo</span>
                <ExternalLink className="h-5 w-5 text-purple-600" />
              </a>
              <a
                href={project.github}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between p-4 bg-gradient-to-r from-green-100 to-purple-100 rounded-lg hover:from-green-200 hover:to-purple-200 transition-colors"
              >
                <span className="font-semibold text-gray-800">Source Code</span>
                <Github className="h-5 w-5 text-purple-600" />
              </a>
              <a
                href={project.prototype}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between p-4 bg-gradient-to-r from-green-100 to-purple-100 rounded-lg hover:from-green-200 hover:to-purple-200 transition-colors"
              >
                <span className="font-semibold text-gray-800">Prototype</span>
                <ExternalLink className="h-5 w-5 text-purple-600" />
              </a>
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="bg-gradient-to-r from-green-600 via-yellow-500 to-purple-600 rounded-xl p-8 text-white text-center"
      >
        <h2 className="text-2xl font-bold mb-4">Interested in a Similar Project?</h2>
        <p className="mb-6">Let's discuss how we can create a custom solution for your needs.</p>
        <Link
          to="/contact"
          className="inline-flex items-center px-6 py-3 bg-white text-purple-600 font-semibold rounded-lg hover:bg-gray-100 transition-colors"
        >
          Get in Touch
          <ArrowRight className="ml-2 h-5 w-5" />
        </Link>
      </motion.div>
    </div>
  );
};

export default ProjectDetails;