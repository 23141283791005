import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import DigitalMarketing from './pages/services/DigitalMarketing';
import AIAutomation from './pages/services/AIAutomation';
import WebDevelopment from './pages/services/WebDevelopment';
import TechnicalConsulting from './pages/services/TechnicalConsulting';
import ProjectDetails from './pages/projects/ProjectDetails';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-8">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/services/ai-automation" element={<AIAutomation />} />
          <Route path="/services/web-development" element={<WebDevelopment />} />
          <Route path="/services/technical-consulting" element={<TechnicalConsulting />} />
          <Route path="/portfolio/:id" element={<ProjectDetails />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;