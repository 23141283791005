import { motion } from 'framer-motion';
import {
  LineChart,
  Zap,
  Code,
  MessageSquare,
  Search,
  Share2,
  PenTool,
  Database,
  Bot,
  Workflow,
  Layout,
  Server
} from 'lucide-react';

const Services = () => {
  const services = [
    {
      category: 'Digital Marketing',
      items: [
        {
          icon: Search,
          title: 'SEO Optimization',
          description: 'Improve your search rankings and online visibility',
        },
        {
          icon: Share2,
          title: 'Social Media Marketing',
          description: 'Engage your audience across social platforms',
        },
        {
          icon: PenTool,
          title: 'Content Strategy',
          description: 'Create compelling content that converts',
        },
      ],
    },
    {
      category: 'AI Solutions',
      items: [
        {
          icon: Bot,
          title: 'AI Integration',
          description: 'Implement AI solutions for your business needs',
        },
        {
          icon: Workflow,
          title: 'Workflow Automation',
          description: 'Streamline processes with AI-powered automation',
        },
        {
          icon: Database,
          title: 'Data Analysis',
          description: 'Extract insights from your business data',
        },
      ],
    },
    {
      category: 'Web Development',
      items: [
        {
          icon: Layout,
          title: 'Website Development',
          description: 'Custom websites tailored to your needs',
        },
        {
          icon: Server,
          title: 'Web Applications',
          description: 'Scalable and responsive web applications',
        },
        {
          icon: Code,
          title: 'Technical Integration',
          description: 'Seamless integration of third-party services',
        },
      ],
    },
  ];

  return (
    <div className="space-y-16">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="text-center"
      >
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Services</h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Comprehensive digital solutions to help your business thrive in the modern age.
        </p>
      </motion.div>

      {services.map((category, categoryIndex) => (
        <section key={category.category} className="space-y-8">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: categoryIndex * 0.1 }}
            className="text-3xl font-bold text-gray-900 text-center"
          >
            {category.category}
          </motion.h2>

          <div className="grid md:grid-cols-3 gap-8">
            {category.items.map((service, index) => (
              <motion.div
                key={service.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: (categoryIndex * 0.3) + (index * 0.1) }}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
              >
                <service.icon className="h-12 w-12 text-indigo-600 mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-600">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </section>
      ))}

      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.9 }}
        className="bg-indigo-600 text-white rounded-2xl p-12 text-center"
      >
        <h2 className="text-3xl font-bold mb-4">Need a Custom Solution?</h2>
        <p className="text-lg mb-8 max-w-2xl mx-auto">
          Let's discuss your specific requirements and create a tailored solution for your business.
        </p>
        <a
          href="/contact"
          className="inline-block px-6 py-3 bg-white text-indigo-600 font-semibold rounded-lg hover:bg-gray-100 transition-colors"
        >
          Get in Touch
        </a>
      </motion.section>
    </div>
  );
};

export default Services;