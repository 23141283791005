import { Github, Linkedin, Mail, MapPin, Phone, Globe } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-white/80 backdrop-blur-md shadow-inner mt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h3 className="text-lg font-semibold gradient-text">Bradley Franklin Marketing</h3>
            <div className="flex items-center justify-center md:justify-start text-gray-600 mt-2">
              <MapPin className="h-4 w-4 mr-2 text-green-500" />
              <p>1665 NW Knoxville Blvd, Bend, OR 97703</p>
            </div>
            <div className="flex items-center justify-center md:justify-start text-gray-600 mt-2">
              <Phone className="h-4 w-4 mr-2 text-yellow-500" />
              <p>(760) 885-9729</p>
            </div>
            <div className="flex items-center justify-center md:justify-start text-gray-600 mt-2">
              <Globe className="h-4 w-4 mr-2 text-purple-500" />
              <a href="https://bradleyfranklin.com" className="hover:gradient-text transition-colors">
                bradleyfranklin.com
              </a>
            </div>
          </div>
          
          <div className="flex space-x-6">
            <a href="https://github.com" className="text-gray-600 hover:gradient-text transition-colors">
              <Github className="h-6 w-6" />
            </a>
            <a href="https://linkedin.com" className="text-gray-600 hover:gradient-text transition-colors">
              <Linkedin className="h-6 w-6" />
            </a>
            <a href="mailto:info@bradleyfranklin.com" className="text-gray-600 hover:gradient-text transition-colors">
              <Mail className="h-6 w-6" />
            </a>
          </div>
        </div>
        
        <div className="mt-8 text-center text-gray-500 text-sm">
          <p>© {new Date().getFullYear()} Bradley Franklin Marketing. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;