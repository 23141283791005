import ServiceLayout from './ServiceLayout';

const DigitalMarketing = () => {
  const features = [
    {
      title: 'SEO Optimization',
      description: 'Improve your search rankings and drive organic traffic through data-driven SEO strategies.',
    },
    {
      title: 'Content Strategy',
      description: 'Develop engaging content that resonates with your audience and drives conversions.',
    },
    {
      title: 'Social Media Management',
      description: 'Build and maintain a strong social media presence across multiple platforms.',
    },
    {
      title: 'Analytics & Reporting',
      description: 'Track and analyze performance metrics to optimize your marketing campaigns.',
    },
    {
      title: 'Email Marketing',
      description: 'Create targeted email campaigns that nurture leads and drive engagement.',
    },
    {
      title: 'PPC Advertising',
      description: 'Manage paid advertising campaigns across Google, social media, and other platforms.',
    },
  ];

  const technologies = [
    'Google Analytics',
    'SEMrush',
    'Ahrefs',
    'Mailchimp',
    'Hootsuite',
    'Google Ads',
    'Facebook Ads Manager',
    'WordPress',
  ];

  return (
    <ServiceLayout
      title="Digital Marketing Services"
      description="Drive growth and engagement with comprehensive digital marketing solutions tailored to your business needs."
      features={features}
      technologies={technologies}
      image="https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80&w=1500"
    />
  );
};

export default DigitalMarketing;