import ServiceLayout from './ServiceLayout';

const WebDevelopment = () => {
  const features = [
    {
      title: 'Custom Website Development',
      description: 'Create beautiful, responsive websites tailored to your brand and needs.',
    },
    {
      title: 'Web Applications',
      description: 'Build powerful web applications with modern frameworks and technologies.',
    },
    {
      title: 'E-commerce Solutions',
      description: 'Develop secure and scalable e-commerce platforms for your business.',
    },
    {
      title: 'Progressive Web Apps',
      description: 'Create fast, reliable, and engaging progressive web applications.',
    },
    {
      title: 'API Development',
      description: 'Design and implement robust APIs for your web applications.',
    },
    {
      title: 'Performance Optimization',
      description: 'Optimize your web applications for speed and efficiency.',
    },
  ];

  const technologies = [
    'React',
    'Next.js',
    'Node.js',
    'TypeScript',
    'Tailwind CSS',
    'PostgreSQL',
    'GraphQL',
    'AWS',
  ];

  return (
    <ServiceLayout
      title="Web Development Services"
      description="Create powerful web applications and websites using modern technologies and best practices."
      features={features}
      technologies={technologies}
      image="https://images.unsplash.com/photo-1517134191118-9d595e4c8c2b?auto=format&fit=crop&q=80&w=1500"
    />
  );
};

export default WebDevelopment;